import React from 'react'

function Footer() {
    return (
        <div className='container my-5'>
            <div className="row d-flex align-items-center justify-content-end">
                <div className="col-12 d-flex align-items-center justify-content-end">

                    Contact us on Whatsapp:&nbsp;&nbsp;<a href='https://wa.me/+966500331120' style={{ color: 'inherit' }}> +966 50 033 1120</a>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-end">
                    Follow us:&nbsp;&nbsp;<a href="https://www.instagram.com/couturechocolate/?hl=en">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_41_698)">
                                <path d="M15.595 4.20459H8.40505C6.08505 4.20459 4.20471 6.08526 4.20471 8.40492V15.5949C4.20471 17.9149 6.08538 19.7953 8.40538 19.7953H15.5947C17.9147 19.7953 19.7954 17.9146 19.7954 15.5946V8.40492C19.7954 6.08492 17.915 4.20459 15.595 4.20459Z" fill="transparent" />
                                <path d="M17.0729 1H6.92709C3.65334 1 1 3.65381 1 6.92709V17.0729C1 20.3467 3.65381 23 6.92756 23H17.0724C20.3462 23 23 20.3462 23 17.0724V6.92709C23 3.65334 20.3467 1 17.0729 1Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
                                <path d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
                                <path d="M18 6C18.5523 6 19 5.55229 19 5C19 4.44772 18.5523 4 18 4C17.4477 4 17 4.44772 17 5C17 5.55229 17.4477 6 18 6Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_41_698">
                                    <rect width="24" height="24" fill="" />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer