import './App.css';
import Logo from './Media/Logo.png'
import { Route, Routes, useNavigate } from 'react-router-dom';
import Product from './Components/Product/Product';
import Footer from './Components/Footer/Footer';


export const Products = [

  {
    id: '0',
    Title: "Colored Plexi Boxes",
    Image: "2.jpeg",
    Document: "1price-list-PLEXI__.pdf"
  },
  {
    id: '1',
    Title: "Colored Plexi Boxes | Saudi Heritage",
    Image: "1.jpeg",
    Document: "2price-list-ETHAR_2024.pdf"
  },
  {
    id: '2',
    Title: "Ikat Silk-Velvet Boxes",
    Image: "3.jpeg",
    Document: "3price-list-Ikat-SilkVelvet.pdf"
  },
  {
    id: '3',
    Title: "Mother of Pearl Boxes | Lacquered",
    Image: "4.jpeg",
    Document: "4price-list-LACQUERED2.pdf"
  },
  {
    id: '4',
    Title: "Mother of Pearl Boxes | Walnut wood",
    Image: "5.jpeg",
    Document: "5price-list-mother-of-pearl.pdf"
  },
  {
    id: '5',
    Title: "Mother of Pearl Boxes | Round ",
    Image: "6.jpeg",
    Document: "6price-list-round-mop-new.pdf"
  },
  {
    id: '6',
    Title: "Mother of Pearl Tables",
    Image: "7.jpeg",
    Document: "7price-list-Tables.pdf"
  }

]

function App() {

  const navigate = useNavigate();

  const productClick = (id) => {
    navigate(`/product/${id}`); // Pass the id instead of index
  }
  const renderProducts = (item, index) => {



    return (
      <div className="col-12 mt-5 col-md-6 d-flex align-items-center justify-content-center col-lg-3" id='Product' onClick={() => productClick(item.id)} key={index}>
        <div className="row">
          <div className="col-12 Box d-flex align-items-center justify-content-center">
            <img src={require(`./Media/${item.Image}`)} width="100%" height="auto" alt="" style={{ borderRadius: '20px', boxShadow: '0px 5px 5px 0px rgb(0,0,0,0.4' }} />
          </div>
          <div className="col-12 text-center d-flex align-items-center justify-content-center">
            <h3 className='mt-5' style={{ height: '50px' }}>

              {item.Title}
            </h3>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>

      <Routes>
        <Route path="/" element={

          <div className="container">
            <div className="row">

              <div className="col-12 my-2 d-flex justify-content-center">
                <img src={Logo} width="300px" height="auto" alt="Couture Logo" />
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="row d-flex align-items-center justify-content-center">
                      {Products.map(renderProducts)}
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        } />
        <Route path="/product/:id" element={<Product />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
