import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Products } from '../../App';

function Product() {
    const { id } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {

        const selectedProduct = Products.find(product => product.id === id);

        setProduct(selectedProduct);
    }, [id]);

    return (
        <div className='container'>
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-12 mt-5 d-flex align-items-center">

                    <strong>
                        <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>

                            &lt;- BACK
                        </a>
                    </strong>
                </div>
                {product ? (
                    <div className="row d-flex align-items-center justify-content-center">

                        <div className="col-12 text-center">
                            <h1 className='my-5'>{product.Title}</h1>


                        </div>
                        <div className="col-12 d-flex align-items-center justify-content-center" style={{ height: '70vh' }}>
                            <iframe

                                title="PDF Viewer"
                                src={require(`../../Media/${product.Document}`)}
                                width="100%"
                                height="100%"
                            ></iframe>
                        </div>
                    </div>
                ) : (
                    <div className='col-12'>
                        Loading ....
                    </div>
                )}
            </div>
        </div>
    );
}

export default Product;
